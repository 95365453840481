import React, { useEffect } from 'react'
import Div100vh from 'react-div-100vh'
import useSms from 'myHooks/useSms'
import { SmsOptInProvider } from 'myContexts/SmsOptInContext'
import { smsOptInStages } from 'myUtils/constants'
import SmsForm from './smsOptIn/SmsForm'
import SmsSent from './smsOptIn/SmsSent'
import './smsOptIn/_style/SmsOptIn.css'

const {
  LOADING,
  FORM,
  SENT
} = smsOptInStages

const SmsOptIn = () => {
  const smsData = useSms()
  const {
    init,
    backgroundStyle,
    stage
  } = smsData

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getBackgroundStyle = () => {
    const { innerWidth } = window
    if (innerWidth > 900) return backgroundStyle
    return {}
  }

  return (
    <Div100vh>
      <div style={getBackgroundStyle()} className='web-SmsOptIn'>
        <SmsOptInProvider value={smsData}>
          {stage === LOADING && <div>Loading...</div>}
          {stage === FORM && <SmsForm />}
          {stage === SENT && <SmsSent />}
        </SmsOptInProvider>
      </div>
    </Div100vh>
  )
}

export default SmsOptIn
