import './_style/CreditCardError.css'
import React, { useContext } from 'react'
import CreditCardContext from 'myContexts/CreditCardContext'
import Logo from 'myComponents/Logo'

const CreditCardError = () => {
  const {
    logo,
    errorMsg
  } = useContext(CreditCardContext)

  return (
    <div className='web-CreditCardError'>
      {logo && <Logo logo={logo} />}
      <div className='web-CreditCardError-message'>{errorMsg}</div>
    </div>
  )
}

export default CreditCardError
