import { useState, useEffect, useMemo } from 'react'
import { getValues } from 'myUtils/firebase'
import { useApolloClient } from '@apollo/react-hooks'
import { GET_EVENT_DETAILS, GET_EVENT_SESSIONS } from 'myUtils/queries'
import { sessionStages } from 'myUtils/constants'
import { useParams } from 'react-router-dom';
import moment from 'moment'

const { LOADING, ERROR, SUCCESS } = sessionStages

function getStyle (style) {
  try {
    const json = JSON.parse(style)
    if (typeof json === 'object') {
      return json
    }
  } catch (err) {
    return null
  }
}

const useSessions = () => {
  const [logo, setLogo] = useState(null);
  const [teamName, setTeamName] = useState(null);
  const [stage, setStage] = useState(LOADING);
  const [backgroundStyle, setBackgroundStyle] = useState({});
  const [errorMsg, setErrorMsg] = useState(null);

  const [dateFilter, setDateFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState([]);
  
  const [eventTimezone, setEventTimezone] = useState('');
  const [eventStartDatetime, setEventStartDatetime] = useState('');
  const [eventEndDatetime, setEventEndDatetime] = useState('');
  const [calendarStart, setCalendarStart] = useState('');
  const [calendarEnd, setCalendarEnd] = useState('');
  const [controlsDisabled, setControlsDisabled] = useState(false);

  const { pk } = useParams();
  const client = useApolloClient();

  const fetchEventSessions = async () => {
    if (!loading) setLoading(true);

    try {
      const { data } = await client.query({
        query: GET_EVENT_SESSIONS,
        variables: { 
          eventDomain: process.env.REACT_APP_ENV === 'development' ? 'ikram.conciergeteam.co' : window.location.hostname,
          guestAppPk: pk ?? '',
          dateFilter: dateFilter.format('YYYY-MM-DD'),
        },
      });
  
      if (!data || !data.guestSessionsForCustomer) {
        throw new Error('Invalid Token');
      } else {
        setStage(SUCCESS);
        setSessions(data.guestSessionsForCustomer);
      }
    } catch (err) {
      console.error(err);
      setErrorMsg('Invalid Token');
      setStage(ERROR);
    }

    setLoading(false);
  };
  
  const fetchCompany = async () => {
    try {
      let company = process.env.REACT_APP_ENV === 'development' ? 'ikram_conciergeteam_co' : window.location.hostname.replaceAll('.', '_');

      const { logo, teamName, backgroundStyle } = await getValues(`/companies/${company}`);

      if (backgroundStyle) setBackgroundStyle(getStyle(backgroundStyle));
      if (logo) setLogo(logo);
      if (teamName) setTeamName(teamName);
    } catch (err) {
      console.error(err);
      setErrorMsg('Error while fetching company');
    }
  };

  const fetchEventDetails = async () => {
    try {
      const { data: { guestEvent: { startDatetimeUtc, endDatetimeUtc, timezone } } } = await client.query({
        query: GET_EVENT_DETAILS,
        variables: { 
          eventDomain: process.env.REACT_APP_ENV === 'development' ? 'ikram.conciergeteam.co' : window.location.hostname,
        },
      });
  
      // const eventStart = moment.tz('2024-10-19T17:16:26Z', timezone).startOf('day');
      // const eventEnd = moment.tz('2024-10-26T17:16:26Z', timezone).startOf('day');

      const eventStart = moment.tz(startDatetimeUtc, timezone).startOf('day');
      const eventEnd = moment.tz(endDatetimeUtc, timezone).startOf('day');
      const today = moment.tz(timezone).startOf('day');
      const calculatedDiffDays = eventEnd.diff(eventStart, 'days') + 1; // Added +1 to include endDate

      let dateFilter = eventEnd;
      let calStart = eventStart;
      let calEnd = eventEnd;

      if (today.isBefore(eventStart)) {
        dateFilter = calStart = eventStart;
      } else if (today.isSameOrBefore(eventEnd)) {
        dateFilter = today;
        calStart = eventStart;
      }

      const daysRange = Math.min(calculatedDiffDays, 7);
      calEnd = calStart.clone().add(daysRange - 1, 'days');

      if (today.isAfter(eventEnd)) {
        const remainingDays = calculatedDiffDays % 7 || 7;
        calStart = eventEnd.clone().subtract(remainingDays - 1, 'days');
        calEnd = eventEnd;
      }
      
      console.log('start + end', calStart.format('YYYY-MM-DD'), calEnd.format('YYYY-MM-DD'), calculatedDiffDays)
  
      setEventStartDatetime(eventStart);
      setEventEndDatetime(eventEnd);
      setEventTimezone(timezone);
      setDateFilter(dateFilter);
      setCalendarStart(calStart);
      setCalendarEnd(calEnd);
      setControlsDisabled(calculatedDiffDays <= 7);
    } catch (err) {
      console.error(err);
      setErrorMsg('Error while fetching event details');
      setStage(ERROR);
    }
  };
  
  useEffect(() => {
    fetchCompany();
  }, []);

  useEffect(() => {
    fetchEventDetails();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (dateFilter) fetchEventSessions();
  }, [dateFilter]); // eslint-disable-line

  const days = useMemo(() => {
    if (!calendarStart || !calendarEnd || !dateFilter) return [];

    const daysCount = calendarEnd.diff(calendarStart, 'days') + 1;
    return Array.from({ length: daysCount }, (_, i) => {
      const date = calendarStart.clone().add(i, 'days');
      return {
        date: date.format('YYYY-MM-DD'),
        dayName: date.format('ddd')[0],
        dayNumber: date.format('D'),
        isToday: date.isSame(moment(), 'day'),
        isSelected: date.format('YYYY-MM-DD') === dateFilter.format('YYYY-MM-DD'),
      };
    });
  }, [calendarStart, calendarEnd, dateFilter]);
  
  const calculateDays = (startDate, endDate, limit = 7) => {
    const daysDiff = startDate.diff(endDate, 'days');
    return Math.min(Math.abs(daysDiff), limit);
  };

  const handlePrevious = () => {
    const daysToSubtract = calculateDays(calendarStart, eventStartDatetime);

    setCalendarStart(calendarStart.clone().subtract(daysToSubtract, 'days'));
    setCalendarEnd(calendarStart.clone().subtract(1, 'day'));
  };

  const handleNext = () => {
    const daysToAdd = calculateDays(eventEndDatetime, calendarEnd);

    setCalendarStart(calendarEnd.clone().add(1, 'day'));
    setCalendarEnd(calendarEnd.clone().add(daysToAdd, 'days'));
  };

  return {
    backgroundStyle,
    logo,
    teamName,
    stage,
    errorMsg,
    eventStartDatetime,
    eventEndDatetime,
    eventTimezone,
    dateFilter,
    setDateFilter,
    loading,
    sessions,
    days,
    handlePrevious,
    handleNext,
    calendarStart,
    calendarEnd,
    controlsDisabled
  };
};

export default useSessions;