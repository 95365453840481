import gql from 'graphql-tag'

export const CUSTOMER_OPT_IN = gql`
  mutation CustomerOptIn(
  $orgId: ID!,
  $phone: String!,
  $customerEmail: String,
  $kId: String,
  $utmSource: String,
  $utmMedium: String,
  $utmContent: String,
  $utmCampaign: String,
  ) {
    customerOptIn(
    orgId: $orgId,
    phone: $phone,
    customerEmail: $customerEmail,
    kId: $kId,
    utmSource: $utmSource,
    utmMedium: $utmMedium,
    utmContent: $utmContent,
    utmCampaign: $utmCampaign
    ) {
      message
    }
  }
`

export const PROCESS_PAYMENT_METHOD = gql`
  mutation ProcessPaymentMethod($intentId: String!) {
    processPaymentMethod(intentId: $intentId) {
      id
    }
  }
`

export const UNSUBSCRIBE_EMAIL = gql`
  mutation CustomerEmailUnsubscribe($key: String!) {
    customerEmailUnsubscribe(key: $key) {
      message
    }
  }
`
