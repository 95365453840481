import React, { useContext } from 'react'
import Phone from 'myAssets/images/PhoneDone.svg'
import Logo from 'myComponents/Logo'
import SmsOptInContext from 'myContexts/SmsOptInContext'
import { formatPhoneNumber } from 'react-phone-number-input'
import './_style/SmsSent.css'

const SmsSent = () => {
  const {
    logo,
    phone
  } = useContext(SmsOptInContext)

  return (
    <div className='web-SmsSent-container'>
      {logo && <Logo logo={logo} />}
      <div className='web-SmsSent-icon'>
        <div>1</div>
        <img src={Phone} alt='phone' />
      </div>
      <div className='web-SmsSent-title'>Confirm your subscription</div>
      <div className='web-SmsSent-subtitle'>Reply "JOIN" to the message we just texted you at {formatPhoneNumber(phone)}.</div>
    </div>
  )
}

export default SmsSent
