import './_style/CreditCardForm.css'
import React, { useContext } from 'react'
import CreditCardContext from 'myContexts/CreditCardContext'
import Logo from 'myComponents/Logo'
import { StripeProvider, Elements } from 'react-stripe-elements'
import StripeElements from './creditCardForm/StripeElements'

const CreditCardForm = () => {
  const {
    logo,
    stripeData
  } = useContext(CreditCardContext)

  return (
    <div className='web-CreditCardForm'>
      {logo && <Logo logo={logo} />}

      <StripeProvider apiKey={stripeData.paymentMethod.orgStripePublishableKey}>
        <Elements>
          <StripeElements />
        </Elements>
      </StripeProvider>
    </div>
  )
}

export default CreditCardForm
