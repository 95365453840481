import * as firebase from 'firebase/app'
import 'firebase/database'

const firebaseConfigProd = {
  apiKey: 'AIzaSyCeRMzpDCdAu3CxE7jfiAk9lO5E8GIzu-0',
  authDomain: 'concierge-consumer-web-app.firebaseapp.com',
  databaseURL: 'https://concierge-consumer-web-app.firebaseio.com',
  projectId: 'concierge-consumer-web-app',
  storageBucket: 'concierge-consumer-web-app.appspot.com',
  messagingSenderId: '548902858051',
  appId: '1:548902858051:web:e267fa7ccab19dd5cf13f6',
  measurementId: 'G-FYBFPVVB0P'
}

const firebaseConfigStaging = {
  apiKey: 'AIzaSyA1x3wiWi9Oul2rdgE53i5iw0ZWClehh_g',
  authDomain: 'concierge-customer-web-staging.firebaseapp.com',
  databaseURL: 'https://concierge-customer-web-staging.firebaseio.com',
  projectId: 'concierge-customer-web-staging',
  storageBucket: 'concierge-customer-web-staging.appspot.com',
  messagingSenderId: '172637814352',
  appId: '1:172637814352:web:09ee0bf0f5360b5fb55b55',
  measurementId: 'G-4C8XNK7NEH'
}

const app = firebase.initializeApp(process.env.REACT_APP_ENV === 'production' ? firebaseConfigProd : firebaseConfigStaging)

const db = app.database()

async function getValues (path) {
  return new Promise((resolve, reject) => {
    db.ref(path).once('value', (snap) => {
      resolve(snap.val())
    }, (err) => {
      reject(err)
    })
  })
}

export { getValues }
