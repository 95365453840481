import React from 'react'
import Lock from 'myAssets/images/Lock.svg'
import Concierge from 'myAssets/images/Concierge.svg'
import './_style/PoweredByConcierge.css'

const PoweredByConcierge = () => {
  const openConciergeLink = () => {
    window.open('https://www.conciergeteam.co', '_blank')
  }

  return (
    <div onClick={openConciergeLink} className='web-PoweredByConcierge-copyright sticky bottom-0 bg-[#fafafa] py-2 mt-auto'>
      <img src={Lock} alt='lock' />
      <div>Powered by</div>
      <img className='web-PoweredByConcierge-concierge' alt='concierge' src={Concierge} />
    </div>
  )
}

export default PoweredByConcierge
