import React from 'react'
import Rollbar from 'rollbar'
import Proptypes from 'prop-types'

export default class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    const rollbar = process.env.NODE_ENV === 'development'
      ? null
      : new Rollbar({
        accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: process.env.REACT_APP_ENV
        }
      })

    this.state = {
      error: null,
      errorInfo: null,
      rollbar
    }
  }

  componentDidCatch (error, errorInfo) {
    if (this.state.rollbar) this.state.rollbar.error(error)
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render () {
    if (this.state.errorInfo) {
      return (
        <div>Something went wrong!</div>
      )
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: Proptypes.object
}
