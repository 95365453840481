import React from 'react'
import Proptypes from 'prop-types'
import './_style/Logo.css'

const Logo = ({ logo }) => (
  <div className='web-Logo'>
    <img className='web-Logo-img' src={logo} alt='logo' />
  </div>
)

Logo.propTypes = {
  logo: Proptypes.string
}

export default React.memo(Logo)
