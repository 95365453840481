import moment from 'moment-timezone';

export const formatStartEndDate = (start, end, timezone) => {
  const startMoment = moment.utc(start).tz(timezone);
  const endMoment = moment.utc(end).tz(timezone);

  if (!startMoment.isValid() || !endMoment.isValid()) return '';

  return `${startMoment.format("LT")} - ${endMoment.format("LT")}`;
};

// Get the full timezone name (e.g., "Eastern Daylight Time")
export const getTimeZone = (timezone) => {
  const options = {
    timeZone: timezone,
    timeZoneName: 'long',
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  const date = new Date();
  
  return formatter.formatToParts(date).find(part => part.type === 'timeZoneName').value;
}

// Get the timezone abbreviation (e.g., "EDT")
export const timeZoneAbbreviation = (timezone) => {  
  const optionsShort = {
    timeZone: timezone,
    timeZoneName: 'short',
  };
  
  const shortFormatter = new Intl.DateTimeFormat('en-US', optionsShort);
  const date = new Date();

  return shortFormatter.formatToParts(date).find(part => part.type === 'timeZoneName').value;
}