import React, { useState, useContext, useRef, useEffect } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input/input'
import Checkbox from '@uiw/react-checkbox'

import Logo from 'myComponents/Logo'
import SmsOptInContext from 'myContexts/SmsOptInContext'

import Phone from 'myAssets/images/Phone.svg'
import './_style/SmsForm.css'
import PoweredByConcierge from 'myComponents/PoweredByConcierge'
import 'react-phone-number-input/style.css'

const SmsForm = () => {
  const {
    logo,
    sendSms,
    disclaimer,
    headline,
    subheadline,
    disabledButton,
    submitText,
    buttonStyleDeactivated,
    buttonStyle
  } = useContext(SmsOptInContext)
  const [phone, setPhone] = useState('')
  const [isTermsAgreed, setIsTermsAgreed] = useState(false)
  const phoneRef = useRef(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (phoneRef && phoneRef.current && phoneRef.current.input) phoneRef.current.input.focus()
  }, [])

  const savePhone = () => {
    const error = phone ? (isValidPhoneNumber(phone) ? null : 'Invalid phone number') : 'Phone number required'
    if (error) setError(error)
    else sendSms(phone)
  }

  const handleCheckboxChange = () => {
    setIsTermsAgreed(!isTermsAgreed)
  }

  const isButtonDisabled = !isValidPhoneNumber(phone) || disabledButton

  return (
    <div className='web-SmsForm-container'>
      {logo && <Logo logo={logo} />}
      {headline && <div className='web-SmsForm-title'>{headline}</div>}
      {subheadline && <div className='web-SmsForm-subtitle'>{subheadline}</div>}
      <div className='web-SmsForm-input-container'>
        <img src={Phone} alt='phone' className='web-SmsForm-icon' />
        <PhoneInput
          ref={phoneRef}
          placeholder='Phone number'
          value={phone}
          onChange={value => setPhone(value)}
          className='web-SmsForm-input'
          country='US'
        />
      </div>
      {error && <div className='web-SmsForm-error'>{error}</div>}
      <button
        disabled={isButtonDisabled}
        onClick={savePhone}
        className={`u-button ${buttonStyleDeactivated || isValidPhoneNumber(phone) ? '' : 'u-button-disabled'}`}
        style={isValidPhoneNumber(phone) ? buttonStyle : buttonStyleDeactivated}
      >
        {submitText}
      </button>
      {!isTermsAgreed && <div className='web-SmsForm-error'>Please check the box to confirm that you agree to the terms.</div>}
      <Checkbox className='web-SmsForm-checkbox' onChange={handleCheckboxChange}>
        <div className='web-SmsForm-disclaimer'>{disclaimer}</div>
      </Checkbox>
      <PoweredByConcierge />
    </div>
  )
}

export default SmsForm
