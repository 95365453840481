import { useContext } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { classNames } from "myUtils/misc";
import moment from 'moment';
import SessionsContext from 'myContexts/SessionsContext';

const Filter = () => {
  const { dateFilter, setDateFilter, days, handlePrevious, handleNext, eventStartDatetime, eventEndDatetime, calendarStart, calendarEnd, controlsDisabled } = useContext(SessionsContext);

  return (
    <div className='bg-gray-50 border rounded-t-lg sticky top-0'>
      <div className="flex h-full flex-col">
        <header className="flex items-center justify-between border-b border-gray-200 px-[1.25rem] py-3">
          <div>
            <h1 className="text-base font-semibold leading-6 text-gray-900 m-0">
              <time dateTime={dateFilter}>
                {dateFilter.format('MMM DD, YYYY')}
              </time>
            </h1>
            <p className="text-sm text-gray-500 m-0">
              {dateFilter.format('dddd')}
            </p>
          </div>
          {!controlsDisabled && (
            <div className="flex items-center">
              <div className="relative flex items-center rounded-md bg-white shadow-sm">
                <button
                  type="button"
                  onClick={handlePrevious}
                  className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative disabled:cursor-not-allowed"
                  disabled={calendarStart.isSameOrBefore(eventStartDatetime)}
                >
                  <span className="sr-only">Previous week</span>
                  <ChevronLeftIcon className="h-5 w-5 pointer" aria-hidden="true" />
                </button>
                <span className="relative -mx-px h-5 w-px bg-gray-300" />
                <button
                  type="button"
                  onClick={handleNext}
                  className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative disabled:cursor-not-allowed"
                  disabled={calendarEnd.isSameOrAfter(eventEndDatetime)}
                >
                  <span className="sr-only">Next week</span>
                  <ChevronRightIcon className="h-5 w-5 pointer" aria-hidden="true" />
                </button>
              </div>
            </div>
          )}
        </header>

        <div className="isolate flex flex-auto overflow-hidden bg-white individual-date">
          <div className="flex flex-auto flex-col overflow-auto">
            <div className="grid flex-none grid-cols-7 bg-white text-xs text-gray-500 shadow ring-1 ring-black ring-opacity-5">
              {days.map((day) => (
                <button
                  key={day.date}
                  type="button"
                  onClick={() => setDateFilter(moment(day.date))}
                  className="flex flex-col items-center pb-1.5 pt-3"
                >
                  <span className='mb-2'>{day.dayName}</span>
                  <time
                    dateTime={day.date}
                    className={classNames(
                      'mx-auto flex h-8 w-8 items-center justify-center rounded-full font-14 text-gray-900',
                      day.isToday && 'font-semibold',
                      day.isSelected && 'bg-gray-900 text-white font-normal'
                    )}
                  >
                    {day.dayNumber}
                  </time>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;