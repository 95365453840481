import React, { useEffect } from 'react'
import Div100vh from 'react-div-100vh'
import useCreditCard from 'myHooks/useCreditCard'
import { CreditCardProvider } from 'myContexts/CreditCardContext'
import CreditCardForm from './creditCard/CreditCardForm'
import CreditCardError from './creditCard/CreditCardError'
import CreditCardConfirmation from './creditCard/CreditCardConfirmation'
import './creditCard/_style/CreditCard.css'
import { creditCardStages } from 'myUtils/constants'

const {
  LOADING,
  FORM,
  ERROR,
  CONFIRMATION
} = creditCardStages

const CreditCard = () => {
  const ccData = useCreditCard()
  const {
    stage,
    backgroundStyle,
    init
  } = ccData

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getBackgroundStyle = () => {
    const { innerWidth } = window
    if (innerWidth > 900) return backgroundStyle
    return {}
  }

  return (
    <Div100vh>
      <div style={getBackgroundStyle()} className='web-CreditCard'>
        <CreditCardProvider value={ccData}>
          {stage === LOADING && <div>Loading...</div>}
          {stage === FORM && <CreditCardForm />}
          {stage === ERROR && <CreditCardError />}
          {stage === CONFIRMATION && <CreditCardConfirmation />}
        </CreditCardProvider>
      </div>
    </Div100vh>
  )
}

export default CreditCard
