import React, {useEffect, useState} from 'react'
import Div100vh from 'react-div-100vh'
import {unsubscribeStages} from 'myUtils/constants'
import {UNSUBSCRIBE_EMAIL} from 'myUtils/mutations'
import {useLocation} from "react-router-dom";
import {useMutation} from '@apollo/react-hooks'
import queryString from 'query-string'
import Logo from "../myComponents/Logo";
import useOrg from 'myHooks/useOrg'
import PoweredByConcierge from "../myComponents/PoweredByConcierge";
import {UnsubscribeProvider} from "../myContexts/UnsubscribeContext";

const {LOADING, ERROR, UNSUBSCRIBED} = unsubscribeStages

const Unsubscribe = () => {
  const orgData = useOrg();
  const {
    init,
    logo,
    backgroundStyle,
    teamName
  } = orgData

  const [stage, setStage] = useState(LOADING)
  const [errorMessage, setErrorMessage] = useState()
  const [successMessage, setSuccessMessage] = useState()
  const [email, setEmail] = useState()

  const location = useLocation();
  // eslint-disable-next-line
  const [unsubcribeEmail, {data}] = useMutation(UNSUBSCRIBE_EMAIL);
  useEffect(() => {
    init();
    const values = queryString.parse(location.search)
    unsubcribeEmail({variables: {key: values.key}}).then(
      (result) => {
        setSuccessMessage(result.data.customerEmailUnsubscribe.message)
        setEmail(result.data.customerEmailUnsubscribe.email)
        setStage(UNSUBSCRIBED)
      }
    ).catch(
      (error) => {
        setStage(ERROR);
        setErrorMessage(error.message);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getBackgroundStyle = () => {
    const {innerWidth} = window
    if (innerWidth > 900) return backgroundStyle
    return {}
  }

  return (
    <Div100vh>
      <div style={getBackgroundStyle()} className='web-SmsOptIn'>
        <UnsubscribeProvider value={orgData}>
          <div className='web-SmsForm-container'>
            {logo && <Logo logo={logo}/>}
            <div className='web-SmsForm-title'>Unsubscribe from {teamName}</div>
            <br/>
            {stage === LOADING && <div>Loading...</div>}
            {stage === ERROR && <div className='web-SmsForm-error'><p>{errorMessage}</p></div>}
            {stage === UNSUBSCRIBED && <div><p>{successMessage}</p><p>{email}</p></div>}
            <br/>
            <PoweredByConcierge/>
          </div>
        </UnsubscribeProvider>
      </div>
    </Div100vh>
  )
}

export default Unsubscribe
