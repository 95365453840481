import gql from 'graphql-tag'

export const PAYMENT_METHOD_QUERY = gql`
  query paymentMethod($encryptedToken: String!) {
    paymentMethod(encryptedToken: $encryptedToken) {
      id
      intentId
      orgId
      clientSecret
      orgStripePublishableKey
    }
  }
`

export const GET_EVENT_DETAILS = gql`
  query GuestEventQuery($eventDomain: String!) {
    guestEvent(eventDomain: $eventDomain) {
      name
      timezone
      startDatetimeUtc
      endDatetimeUtc
    }
  }
`

export const GET_EVENT_SESSIONS = gql`
  query GuestSessionsForCustomerQuery($eventDomain: String!, $guestAppPk: String, $dateFilter: String) {
    guestSessionsForCustomer(eventDomain: $eventDomain, guestAppPk: $guestAppPk, dateFilter: $dateFilter) {
      id
      name
      description
      locationName
      locationUrl
      startDatetimeUtc
      endDatetimeUtc
      createdAt
      updatedAt
    }
  }
`