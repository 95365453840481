import './_style/CreditCardConfirmation.css'
import React, { useContext } from 'react'
import CreditCardContext from 'myContexts/CreditCardContext'
import Logo from 'myComponents/Logo'

const CreditCardConfirmation = () => {
  const {
    logo,
    addCardConfirmationMessage
  } = useContext(CreditCardContext)

  return (
    <div className='web-CreditCardConfirmation'>
      {logo && <Logo logo={logo} />}
      <div className='web-CreditCardConfirmation-title'>Thanks!</div>
      <div className='web-CreditCardConfirmation-subtitle'>{addCardConfirmationMessage}</div>
    </div>
  )
}

export default CreditCardConfirmation
