import { getTimeZone, timeZoneAbbreviation } from 'myUtils/formatter'

const TimezoneInfo = ({ timezone }) => {
  return (
    <p className='text-base text-gray-700 px-1 mt-1 mb-3'>
      All times in {getTimeZone(timezone)} ({timeZoneAbbreviation(timezone)})  
    </p>
  );
}

export default TimezoneInfo;