import React, { useContext, useRef, useState } from 'react'
import CreditCardContext from 'myContexts/CreditCardContext'
import {
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
  injectStripe
} from 'react-stripe-elements'
import CreditCard from 'myAssets/images/CreditCard.svg'
import Cvc from 'myAssets/images/Cvc.svg'
import Expirity from 'myAssets/images/Expirity.svg'
import Proptypes from 'prop-types'
import PoweredByConcierge from 'myComponents/PoweredByConcierge'
import './_style/StripeElements.css'

const StripeElements = ({ stripe }) => {
  const cardNameRef = useRef(null)
  const [cardNameError, setCardNameError] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [cardNumberError, setCardNumberError] = useState(null)
  const [expirityError, setExpirityError] = useState(null)
  const [cvcError, setCvcError] = useState(null)
  // const [creditCardIcon, setCreditCartIcon] = useState(CreditCard)
  const [creditCardIcon] = useState(CreditCard)
  const {
    teamName,
    buttonStyleDeactivated,
    buttonStyle,
    savePayment,
    invalidToken,
    stripeData
  } = useContext(CreditCardContext)

  const handleStripeError = error => {
    const { param } = error
    if (param === 'cvc') setCvcError(true)
  }

  const onSubmit = async () => {
    try {
      const stripeRes = await stripe
        .handleCardSetup(stripeData.paymentMethod.clientSecret, {
          payment_method_data: {
            billing_details: { name: cardNameRef.current.value }
          }
        })

      if (stripeRes &&
      stripeRes.setupIntent &&
      stripeRes.setupIntent.status === 'succeeded') {
        savePayment()
      } else {
        const { error } = stripeRes
        if (error) handleStripeError(error)
        else invalidToken()
      }
    } catch (e) {
      invalidToken()
    }
  }

  // const changeCreditCardIcon = brand => {
  // setCreditCartIcon(CreditCard)
  // }

  const onChange = ev => {
    setShowErrors(true)
    if (cardNameRef && !cardNameRef.current.value) setCardNameError(true)
    else setCardNameError(false)

    if (ev.error) {
      if (ev.elementType === 'cardExpiry') setExpirityError(true)
      if (ev.elementType === 'cardNumber') setCardNumberError(true)
      if (ev.elementType === 'cardCvc') setCvcError(true)
    } else {
      if (ev.complete === false) {
        if (ev.elementType === 'cardExpiry') setExpirityError(null)
        if (ev.elementType === 'cardNumber') setCardNumberError(null)
        if (ev.elementType === 'cardCvc') setCvcError(null)
      } else {
        if (ev.elementType === 'cardExpiry') setExpirityError(false)
        if (ev.elementType === 'cardNumber') setCardNumberError(false)
        if (ev.elementType === 'cardCvc') setCvcError(false)
      }
    }
  }

  const hasError = (
    !showErrors || cardNameError || cardNumberError || expirityError || cvcError ||
    cardNumberError === null || expirityError === null || cvcError === null
  )

  const stripeStyle = { base: { fontSize: '18px' } }
  const errorStyle = error => (error ? { border: '2px solid #b00020' } : {})

  return (
    <div className='web-StripeElements'>
      <div className='web-StripeElements-title'>Link a Payment Method</div>
      <div className='web-StripeElements-subtitle'>Add a credit card to your {teamName} account.</div>
      <div>
        <input
          style={errorStyle(cardNameError)}
          placeholder='Cardholder name'
          className='web-StripeElements-name'
          type='text'
          onChange={onChange}
          ref={cardNameRef}
        />
        {cardNameError && <div className='web-StripeElements-error'>Enter cardholder name</div>}
      </div>
      <div>
        <div style={errorStyle(cardNumberError)} className='web-StripeElements-icon-container'>
          <img src={creditCardIcon} alt='cc' className='web-StripeElements-icon' />
          <CardNumberElement
            onChange={onChange}
            style={stripeStyle}
            className='web-StripeElements-input'
          />
        </div>
        {cardNumberError && <div className='web-StripeElements-error'>Enter a valid 16-digit card number</div>}
      </div>
      <div className='web-StripeElements-column'>
        <div style={errorStyle(expirityError)} className='web-StripeElements-icon-container'>
          <img src={Expirity} alt='cc' className='web-StripeElements-icon' />
          <CardExpiryElement
            style={stripeStyle}
            onChange={onChange}
            className='web-StripeElements-input'
          />
        </div>
        <div style={errorStyle(cvcError)} className='web-StripeElements-icon-container'>
          <img src={Cvc} alt='cc' className='web-StripeElements-icon' />
          <CardCvcElement
            style={stripeStyle}
            onChange={onChange}
            className='web-StripeElements-input'
          />
        </div>
        <div className='web-StripeElements-errors'>
          {expirityError && <div className='web-StripeElements-error'>Enter valid expiration date</div>}
          {cvcError && <div className='web-StripeElements-error'>The security code for this card is invalid. Verify the code and submit the payment again.</div>}
        </div>
      </div>
      <button
        onClick={onSubmit}
        disabled={hasError}
        className={`u-button ${buttonStyleDeactivated || hasError ? '' : 'u-button-disabled'}`}
        style={hasError ? buttonStyleDeactivated : buttonStyle}
      >
          Save Payment
      </button>
      <div className='web-StripeElements-safe'>Your information is safe with us.</div>
      <PoweredByConcierge />
    </div>
  )
}

StripeElements.propTypes = {
  stripe: Proptypes.object
}

export default injectStripe(StripeElements)
